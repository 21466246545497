import * as axios from 'axios';
import config from '../../config';
import Enum from 'enum';
import { useGuidStore } from "@/stores/sessionId";
import { useAuth0 } from '@auth0/auth0-vue';
//import { useWindowSize } from '@vueuse/core';

const enumSessionFields = new Enum(['deviceType', 'deviceName', 'userAgent', 'clientCode',
         'hostname', 'pageProperties', 'definedValues', 'logoAssetID', 'pathName']);
const enumEnvironmentVariableFields = new Enum (['fileStorageUrl', 'ssoClientID', 'ssoDomain', 'audience', 'scope']);

const getEnvironmentVariable = async function(varName) {
    let enVars = sessionStorage.getItem('enVars');
    if(enVars) {
        enVars = JSON.parse(enVars);
        const currentTime = new Date(); 
        if(enVars.expire > currentTime.getTime() && enVars[varName]) {
            return enVars[varName];
        } else {
            sessionStorage.removeItem('enVars');
        }
    }
    try { 
        let resp = await jvGet('/enVars', false);
        const currentTime = new Date();
        //changed to 10 minutes to force getting this value more often.  Most sessions are still less than 10 minutes.
        resp.data.expire = currentTime.getTime() + (10*60000);
        sessionStorage.setItem('enVars', JSON.stringify(resp.data));
        if(resp.data[varName])
            return resp.data[varName];
        else
            RedirectToError();
    }
    catch(error) {    
        const myURL = new URL(location.href);
        if(myURL.hostname === "localhost") {
            if(enumEnvironmentVariableFields.apiUrl.is(varName))
                return config.apiUrl;
            else if(enumEnvironmentVariableFields.fileStorageUrl.is(varName))
                return config.fileStorageUrl;
            else if(enumEnvironmentVariableFields.ssoDomain.is(varName))
                return config.ssoDomain;
            else if(enumEnvironmentVariableFields.ssoClientID.is(varName))
                return config.ssoClientID;
        }
        else {
            console.error(error);
            postErrorLog(error);
            RedirectToError();
        }
    }
}


const getAccordionParameters = async function(code) {
    try{
        const response = await jvGet("/proxy/jellyroll/" + code);
        const parameters = response.data;
        return parameters;
    }
    catch(error){
        console.error(error);
        postErrorLog(error);
        RedirectToError();
    }
}

const getAccordion = async function(code, parameters, additionalMergeAccordionCodes) {

    var params = null;
    if(parameters != null)
        params = new URLSearchParams([['displayParams',  JSON.stringify(parameters)]]);

    var addToQueryString = '';
    if(additionalMergeAccordionCodes)
        addToQueryString = '?MergeAccordionCodes=' + additionalMergeAccordionCodes.join('&MergeAccordionCodes=');

    const response = await jvGet("/proxy/accordion/GetByCode/" + code + addToQueryString, null, null, { params });
    const accordion = response.data;
    return accordion;
};

const getAccordionByID = async function(id, parameters) {
    var params = null;
    if(parameters != null)
        params = new URLSearchParams([['displayParams',  JSON.stringify(parameters)]]);

    params["returnActiveRecordsOnly"] = true;
    
    const response = await jvGet("/proxy/accordion/" + id, null, null, { params });
    const accordion = response.data;
    return accordion;
};
const postFeedback = async function(feedbackObj, useGuidForLogging = true) {
    if(useGuidForLogging === true) {
        const guidStore = useGuidStore();
        feedbackObj.sourceId = guidStore.getSessionId();
        feedbackObj.sourceType = 'GUID'; 
    }   

    feedbackObj.clientCode = await _getSessionData(enumSessionFields.clientCode);
    return await jvPost("/proxy/feedback/", feedbackObj, false);
};

const postActivityLog = async function(activityObj, useGuidForLogging = true) {
    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    var skipLogging = !!params.get("skip_logging");
    
    if(skipLogging)
        return;

    if(useGuidForLogging === true) {
        const guidStore = useGuidStore();
        activityObj.sourceId = guidStore.getSessionId();
        activityObj.sourceType = 'GUID'; 
    }    

    activityObj.deviceType = await _getSessionData(enumSessionFields.deviceType);
    activityObj.deviceName = await _getSessionData(enumSessionFields.deviceName);
    activityObj.userAgent = await _getSessionData(enumSessionFields.userAgent);
    activityObj.clientCode = await _getSessionData(enumSessionFields.clientCode);
    jvPost('/proxy/activityLog', activityObj, false);
////    const baseUrl = await '/proxy/activityLog';
//    const blob = new Blob([JSON.stringify(activityObj)], { type: 'application/json' });
 //   navigator.sendBeacon(baseUrl, JSON.stringify(activityObj));
}

const postErrorLog = async function(error) {
    await jvPost('/postErrorLog', error, false, false);
}

const getMediaFile = async function(fileUrl) {
    const resp = await jvGet('/mediaFile?fileUrl=' + fileUrl, false);
    return resp.data;
}

const getMediaFileProperties = async function(fileUrl) {
    const resp = await jvGet('/mediaFileProperties?fileUrl=' + fileUrl, false);
    if(resp.data && resp.success === true) {
        if(resp.data.ContentType === 'image/gif') 
            resp.data.LastModifiedTicks = convertToTicks(new Date());
        else 
            resp.data.LastModifiedTicks =  convertToTicks(resp.data.LastModified); 
        return resp.data;
    }
    else 
        return {};
}

const convertToTicks = function(dateTime) {
      // .NET Ticks start from 0001-01-01, while JavaScript Date starts from 1970-01-01
      const jsTicks = 621355968000000000;
      const msToTicks = 10000;

      // Convert JavaScript Date object to milliseconds since the epoch
      const dt = new Date(dateTime);

      const dateTimeMs = dt.getTime();

      // Convert milliseconds to .NET ticks
      return jsTicks + (dateTimeMs * msToTicks);
}

const _getSessionData = async function(eSessionField) {
    let sessionData = sessionStorage.getItem('sessionData');
    if(sessionData)
        sessionData = JSON.parse(sessionData);

   // let host = await getUrl();

/*    if(sessionData && sessionData[enumSessionFields.hostname] === host && 
                    sessionData[enumSessionFields.pathName] === window.location.pathname) {
        const currentTime = new Date();
        if(sessionData.expire > currentTime.getTime() && sessionData[eSessionField])
            return sessionData[eSessionField];
        else
            sessionStorage.removeItem('sessionData');
    }
*/
    try {
        let params = (new URL(document.location)).searchParams;
        // let resp = await axios.get('/sessionData');
         let resp = await jvGet('/sessionData?hostName=' + window.location.hostname + '&url=' + window.location.pathname + '&impersonatedUrl=' + params.get("impersonate_url"), false);
        const currentTime = new Date();
        resp.data.expire = currentTime.getTime() + (60*60*1000);
        resp.data[enumSessionFields.pathName] = window.location.pathname;
        sessionStorage.setItem('sessionData', JSON.stringify(resp.data));
        if(resp.data[eSessionField])
            return resp.data[eSessionField];
        else
            return null;
    }
    catch(error) {
        if(error.response.status === 404 && (enumSessionFields.deviceType.is(eSessionField) || enumSessionFields.deviceName.is(eSessionField) ||
                enumSessionFields.userAgent.is(eSessionField)))
            return 'Other';
        else {
            console.error(error);
            postErrorLog(error);
            return undefined;
        }
    }
}

const getClientCode = async function() {
    return await _getSessionData(enumSessionFields.clientCode);
}

const getPageProperties = async function() {
    return await _getSessionData(enumSessionFields.pageProperties);
}

const getClientConstants = async function() {
    return await _getSessionData(enumSessionFields.definedValues);
}

const getClientLogoAssetID  = async function() {
    return await _getSessionData(enumSessionFields.logoAssetID);
}

const getUrl = async function() {
    const myURL = new URL(location.href);
    let params = new URLSearchParams(myURL.searchParams);

    if(myURL.hostname === "localhost" || myURL.hostname === "jv-custom-solutions-web-dev.herokuapp.com") {
        let returnUrl = params.get("impersonate_url");
        return (returnUrl != null) ? returnUrl : myURL.hostname;
    }
    else 
        return myURL.hostname;
}

const getSurvey = async function(surveyId) {
    const response = await jvGet("/proxy/survey/" + surveyId);
    const survey = response.data;
    return survey;
}

const getSurveyByCode = async function(surveyCode, additionalMergeSurveyCodes) {

    var addToQueryString = '';
    if(additionalMergeSurveyCodes)
        addToQueryString = '?MergeSurveyCodes=' + additionalMergeSurveyCodes.join('&MergeSurveyCodes=');

    const response = await jvGet("/proxy/survey/GetByCode/" + surveyCode + addToQueryString);
    const survey = response.data;
    return survey;
}

const getReplacementStringAttributes = async function() {
    const response = await jvGet("/proxy/attribute?replacement_strings_only=true");
    return response.data;
}

const RedirectToError = async function() {
    const myURL = new URL(location.href);
    if(myURL.hostname !== "localhost")
        window.location.href = '/ErrorPage';
    else 
        alert("an error has occurred. See console");
    
}

const getMediaProperty = async function(id) {
    const response = await jvGet("/proxy/mediaproperty/" + id);
    const mediaProperty = response.data;
    return mediaProperty;
}

const postIdomoo = async function(configuration) {
    try {
        const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
        var response =  await axios.post(baseUrl + "/proxy/idomoo/", configuration);

        if(response.status === 200 )
        {
            return {
                checkStatusUrl: response.data.check_status_url,
                videoUrl: response.data.output.video[0].links.url
            };
        }
        else 
            throw "Idomoo request failed";
    }
    catch(error){
        console.error(error);
        postErrorLog(error);
        return undefined;
    }
}

const checkIdomooStatus = async function(statusUrl) {
    const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
    const response = await axios.get(baseUrl + "/proxy/idomoo/GetStatus?checkStatusUrl=" + statusUrl);

    if(response.data.status === "VIDEO_AVAILABLE")
        return true;
    else 
        return false;
}

const saveClientData = async function(id, name, type, clientData) {
    var postData = {
        'clientDataName': name,
        'clientCode': await _getSessionData(enumSessionFields.clientCode),
        'clientDataRecordType': type,
        'clientDataConfiguration': clientData
    }

    var returnData = {
        'id': id,
        'errors': null,
        'success': false
    }

    let response;
    if(id) {
        response = await jvPatch('/proxy/clientdata/' + id, postData);
    } else {
        response = await jvPost('/proxy/clientdata/', postData);
    }
    returnData.id = response.id;
    returnData.errors = response.errors;
    returnData.success = response.success;

    return returnData;
}

const ProcessSaveErrors = function (errors) {

    var retErrors = []
    for (var key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
            var val = errors[key];
            for(var j = 0; j < val.length; j++)
            {
                retErrors.push(key + ' ' + val[j]);
            }
        }
    }
    return retErrors;
}
const loadClientData = async function(id) {
    const clientCode = await _getSessionData(enumSessionFields.clientCode);
    const response = await jvGet("/proxy/clientdata/" + id + "?clientCode=" + clientCode);
    return response.data;
}

const browseClientData = async function(type, searchText, activeOnly) {
    const clientCode = await _getSessionData(enumSessionFields.clientCode);
    if(!activeOnly)
        activeOnly = true;
        
    const response = await jvGet("/proxy/clientdata?client_code=" + clientCode +
        "&client_data_record_type=" + type + "&search_text=" + searchText + "&active_only=" + activeOnly);
    return response.data;
}

const deleteClientData = async function(id) {
    const baseUrl = await getEnvironmentVariable(enumEnvironmentVariableFields.apiUrl);
    const clientCode = await _getSessionData(enumSessionFields.clientCode);

    const response = await axios.delete(baseUrl + "/proxy/clientdata/" + id + "?clientCode=" + clientCode);
    return response.data;
}

const postSingleFileToWasabiMMA = async function(file, path) {    
    let formData = new FormData();
    formData.append('file', file);

    let filename = path.split('/').pop();

    const response = await jvPost('/mmaSingleFile?filename=' + filename, formData, true, false, {headers: {'Content-Type': 'multipart/form-data'}});
    return response.data.location;
}

  async function getHMACToken(formData) {
    const payload = {};
    formData.forEach((value, key) => {
        payload[key] = value;
    });

    const response = await axios.post('/api/getToken', payload);
    return response.data.token;
  }

const postEmail = async function(emailObj) {
    let formData = new FormData();
    for(let i = 0; i < emailObj.attachments.length; i++) {
        formData.append('attachments', emailObj.attachments[i]);
    }
    for(let i = 0; i < emailObj.inlineImages.length; i++) {
        formData.append('inlineImages', emailObj.inlineImages[i]);
    }
    formData.append('from', emailObj.sender);
    formData.append('to', emailObj.recipients);
    formData.append('subject', emailObj.subject);
    formData.append('text', emailObj.text);
    formData.append('html', emailObj.html);
    formData.append('template', emailObj.template || '');
    formData.append('templateType', emailObj.templateType || 'NotSet');
    formData.append('templateVariablesJson', emailObj.templateVariablesJson);
    const timestamp = Math.floor(Date.now() / 1000); // Current time in seconds
    formData.append('timestamp', timestamp);

    const token = await getHMACToken(formData);
    formData.append('token', token);
    const response = await jvPost('/proxy/email/SendEmail', formData, false, true, {headers: {'Content-Type': 'multipart/form-data'}});

    const messageId = response.data;
    return messageId;
}

const getLmsUrl = async function(learnerId, courseId) {    
    var returnData = {
        'url': "",
        'errors': null
    }

    const response = await jvGet("/proxy/lms?learnerID=" + learnerId + "&courseID=" + courseId);
    returnData.url = response.data;

    return returnData;
}

const getIkeaApiInfo = async function() {
    var token = '';
    try {
        const { getAccessTokenSilently } = useAuth0(); 
  
        token = await getAccessTokenSilently({      
            ignoreCache: true,  // This will bypass the cache if supported   
            audience: await getEnvironmentVariable(enumEnvironmentVariableFields.audience),
            scope:  await getEnvironmentVariable(enumEnvironmentVariableFields.scope)
        });
    }
    catch(error){
        console.log(error);
        return {
            success: false,
            code: 403
        }
    }

    try {
        var config = { 
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        // const response = await axios.get('/proxy/secured/Ikea/GetEmployeeInfo', config);
        const response = await jvGet("/proxy/secured/Ikea/GetEmployeeInfo", true, true, config); //" + employeeID );//?apiKey=" + apikey);
        return response.data;

    } 
    catch(error) {
        console.log(error);
    }
}

const Sleep = async function(milliseconds) {    
    const response = await jvGet("/proxy/JV/Sleep/" +milliseconds );
    return response.data;
}

const getLanguages = async function() {
    const response = await jvGet('/proxy/language/');
    return response.data;
}

const jvPatch = async function(uri, saveObject, redirectOnError = true, addBaseUrl = true, configObject = null) {
    var result = {
        success: null,
        errors: null, 
        id: saveObject.id
    }; 

    const apiUrl  = addBaseUrl ? `${window.location.origin}${uri}` : uri;
    let counter = 0;
    while(result.success === null) {
        try {
            await axios.patch(apiUrl, saveObject, configObject)
                        .then( () => { result.success = true;})
        } catch(error) {
            if(error.code === 'ECONNABORTED' && counter < 4)
                counter++;
            else {
                postErrorLog(error);
                let params = new URLSearchParams(uri);
                if(params.get("debug"))
                    console.error(error);
                else if((!error.response || error.response.status !== 422) && redirectOnError)
                    RedirectToError();
                if(error.response)
                    result.errors = ProcessSaveErrors(error.response.data.errors);
                result.success = false;
            }
        }
    }

    return result;
}

const jvPost = async function(uri, saveObject, redirectOnError = true, addBaseUrl = true, configObject = null) {
    var result = {
        success: null,
        errors: null, 
        id: null,
        data: null
    }; 

    const ensureContentType = (config) => {
        if(!config)
            config = {};

        if (!config.headers)
          config.headers = {};
        
        if (!config.headers['Content-Type']) {
          config.headers['Content-Type'] = 'application/json'; // Default to JSON if not set
        }
        return config;
      };
    
    const updatedConfig = ensureContentType(configObject);

    const apiUrl  = addBaseUrl ? `${window.location.origin}${uri}` : uri;

    let counter = 0;
    while(result.success === null) {
        try {
            await axios.post(apiUrl, saveObject, updatedConfig)
                .then((res) => { 
                    result.success = true; 
                    result.id = res.data.id; 
                    if(res.data)
                        result.data = res.data;
                });
        } catch(error) {
            if(error.code === 'ECONNABORTED' && counter < 4)
                counter++;
            else {
                postErrorLog(error);
                let params = new URLSearchParams(uri);
                if(params.get("debug"))
                    console.error(error);
                else if((!error.response || error.response.status !== 422) && redirectOnError)
                    RedirectToError();
                if(error.response)
                    result.errors = ProcessSaveErrors(error.response.data.errors);
                result.success = false;
            }
        }
    }

    return result;
}

const jvGet = async function(uri, addBaseUrl = true, redirectOnError = true, configObject = null) {
    var result = {
        success: null,
        errors: null,
        data: null
    };

    const apiUrl  = addBaseUrl ? `${window.location.origin}${uri}` : uri;
    let counter = 0;
    while(result.success === null) {
        try {
            await axios.get(apiUrl, configObject)
            .then( (resp) => { result.data = resp.data; result.success = true;})
        } catch(error) {
            if(error.code === 'ECONNABORTED' && counter < 4)
                counter++;
            else {
                postErrorLog(error);
                let params = new URLSearchParams(uri);
                if(params.get("debug"))
                    console.error(error);
                else if((!error.response || error.response.status !== 422) && redirectOnError)
                    RedirectToError();
                if(error.response)
                    result.errors = ProcessSaveErrors(error.response.data.errors);
                result.success = false;
            }
        }
    }

    return result;
}

const getBrowserInfo = function() {
    const userAgent = navigator.userAgent;
    let browser = "Unknown";
    let isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(userAgent);

    if (/Chrome\/\d+/.test(userAgent) && !/Edg|OPR|Brave\//.test(userAgent)) {
        browser = "Chrome";
    } else if (/Edg\/\d+/.test(userAgent)) {
        browser = "Edge";
    } else if (/Firefox\/\d+/.test(userAgent)) {
        browser = "Firefox";
    } else if (/Safari\/\d+/.test(userAgent) && !/Chrome|CriOS|FxiOS/.test(userAgent)) {
        browser = "Safari";
    }// else if (/OPR\/\d+/.test(userAgent)) {
     //   browser = "Opera";
   // } else if (/Brave\//.test(userAgent)) {
   //     browser = "Brave";
  //  }

    return { browser, isMobile };
}

export const data = {
    getAccordion,
    getAccordionByID,
    postFeedback,
    postActivityLog,
    postErrorLog,
    getAccordionParameters,
    getEnvironmentVariable,
    getMediaFile,
    getMediaFileProperties,
    convertToTicks,
    enumEnvironmentVariableFields,
    enumSessionFields,
    getUrl,
    getClientCode,
    getPageProperties,
    getSurvey,
    getSurveyByCode,
    getMediaProperty,
    postIdomoo,
    checkIdomooStatus,
    saveClientData,
    loadClientData,
    browseClientData,
    deleteClientData,
    postSingleFileToWasabiMMA,
    postEmail,
    getLmsUrl,
    _getSessionData,
    getReplacementStringAttributes,
    getClientConstants,
    getClientLogoAssetID,
    getIkeaApiInfo,
    Sleep,
    getLanguages,
    getBrowserInfo
};